<template>
  <div class="home">
    <main>
      <AppTitleHeader textClass="text-challenge-mode" data-aos="fade-in" data-aos-delay="500" />
      <template v-if="allSetup">
        <ChallengeResult v-if="finished" @event="sendEvent" :loading="loading" />
        <ChallengeGame @event="sendEvent" :loading="loading" />
        <ChallengeHowToPlay @event="sendEvent" />
      </template>
      <template v-else>
        <ChallengeMain @event="sendEvent" :loading="loading" />
        <ChallengeHowToPlay @event="sendEvent" />
        <ChallengeGame @event="sendEvent" :loading="loading" />
      </template>
      <AppAd v-if="$i18n.locale === 'en'" class="home-section" @event="sendEvent" />
    </main>
  </div>
</template>
<script>
import AppAd from '@/components/containers/AppAd.vue';
import ChallengeMain from '@/components/containers/ChallengeMain.vue';
import ChallengeHowToPlay from '@/components/containers/ChallengeHowToPlay.vue';
import ChallengeGame from '@/components/containers/ChallengeGame.vue';
import ChallengeResult from '@/components/containers/ChallengeResult.vue';
import AppTitleHeader from '@/components/AppTitleHeader.vue';
import challengeModel from '@/models/challenge.model.js';

// import mixpanel from 'mixpanel-browser';

export default {
  name: 'Home',
  metaInfo() {
    return {
      title: this.$t('app.title'),
      titleTemplate: '%s | ' + this.$t('app.challenge'),
      htmlAttrs: {
        lang: this.$root.$i18n.locale,
        amp: true
      }
    };
  },
  data: () => ({
    loading: true
  }),
  mounted() {
    this.$store.commit('setPageTransition', 'default');
    this.loadData();
  },
  computed: {
    finished() {
      if (this.$store.state.challenge.session_data) {
        return this.$store.state.challenge.session_data.finished;
      }
      return false;
    },
    allSetup() {
      const as = this.$route.meta.as;
      if (this.$store.state.challenge.session_data) {
        if (as === 'host') {
          return !!this.$store.state.challenge.session_data.players[0].nickname;
        } else {
          return !!this.$store.state.challenge.session_data.invited;
        }
      }
      return false;
    }
  },
  methods: {
    loadData() {
      const id = this.$route.params.id;
      const as = this.$route.meta.as;
      challengeModel.get(this.axios, as, id).then(data => {
        const { session } = data;
        this.$store.commit('setSession', session);
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      });
    },
    sendEvent(action, properties) {
      try {
        console.log(action, properties);
        // const data = { action: action, ...properties };
        // console.log('stp_multiplayer_event', data);
        // mixpanel.track('stp_multiplayer_event', data);
      } catch (e) {
        console.error(e);
      }
    }
  },
  components: {
    AppAd,
    ChallengeMain,
    ChallengeHowToPlay,
    ChallengeGame,
    ChallengeResult,
    AppTitleHeader
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/custom-vars/_colors.scss';

@keyframes jump {
  0% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(720deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

.home {
  min-height: 100vh;
  > main {
    overflow-x: hidden;
    > section {
      &:not(:first-of-type) {
        margin-top: -20px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
      &:first-of-type {
        padding-top: 8px;
      }
    }
  }
  .loading {
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    position: fixed;
    background: $gradient-literature;
    > .bar {
      width: 32px;
      height: 32px;
      animation: jump 1s ease-out infinite;
      border-radius: 4px;
      background: white;
    }
  }
}

.horizontal-scroll-section {
  width: 100%;
  height: 150px;
}
</style>
