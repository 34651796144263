<template>
  <section id="challenge-game" class="pt-4 pb-5 text-black px-4">
    <SectionTitleHeader :title="sectionTitleText" color="literature" class="pt-4 mb-3" />
    <div class="current-game-grid">
      <MultiplayerPlayerStats ref="p1" id="player-one-stats" :player="0" :player_index="player_index" :stats="player_one" data-aos="fade-right" />
      <MultiplayerPlayerStats ref="p2" id="player-two-stats" :player="1" :player_index="player_index" :stats="player_two" data-aos="fade-left" />
    </div>
    <div class="d-flex mt-4" data-aos="zoom-in" data-aos-offset="-10">
      <div v-if="this.loading" class="flex-fill text-white d-flex justify-content-center align-items-center font-weight-700">
        <div class="loading-square bg-green-neon" style="margin-right:12px"></div>
        Loading
      </div>
      <button v-else-if="!hideActionBtn" type="button" class="btn bg-green-neon flex-fill rounded-button d-flex justify-content-center align-items-center" @click="action">
        {{ CTA }}
      </button>
    </div>
  </section>
</template>
<script>
import MultiplayerPlayerStats from '@/components/MultiplayerPlayerStats';
import SectionTitleHeader from '@/components/SectionTitleHeader.vue';

export default {
  components: {
    MultiplayerPlayerStats,
    SectionTitleHeader
  },
  props: {
    loading: { type: Boolean },
    hideActionBtn: { type: Boolean }
  },
  data: () => ({
    player_one: {
      questions_answered: 0,
      overall_right_answer: 0,
      total_points: 0
    },
    player_two: {
      questions_answered: 0,
      overall_right_answer: 0,
      total_points: 0
    }
  }),
  methods: {
    action() {
      if (this.allSetup) {
        const session = this.$store.state.challenge.session_data;
        let action = 'resume-challenge';

        if (session && session.finished) {
          action = 'new-challenge';
        }
        alert(JSON.stringify({ action }, null, 2));
      } else {
        this.$router.push({ name: 'challenge-' + this.$route.meta.as + '-setup', params: this.$route.params });
      }
    }
  },
  mounted() {
    const as = this.$route.meta.as;
    this.player_one.nickname = as == 'host' ? 'You' : 'Friend';
    this.player_two.nickname = as == 'host' ? 'Friend' : 'You';
    this.$store.subscribe(({ type }, state) => {
      if (type == 'setSession') {
        const p = state.challenge.session_data.players;
        if (p[0]) {
          if (p[0].nickname && as !== 'host') {
            this.player_one.nickname = p[0].nickname;
          }
          if (p[0].total_points) {
            this.player_one.total_points = p[0].total_points;
          }
          if (p[0].questions_answered) {
            this.player_one.questions_answered = p[0].questions_answered;
          }
          if (p[0].goals && p[0].questions_answered) {
            this.player_one.goals = p[0].questions_answered;
          }
          if (p[0].goals && p[0].questions_answered) {
            this.player_one.overall_right_answer = 100 * (p[0].goals / p[0].questions_answered);
          }
        }
        if (p[1]) {
          if (p[1].nickname && as !== 'join') {
            this.player_two.nickname = p[1].nickname;
          }
          if (p[1].total_points) {
            this.player_two.total_points = p[1].total_points;
          }
          if (p[1].questions_answered) {
            this.player_two.questions_answered = p[1].questions_answered;
          }
          if (p[1].goals && p[1].questions_answered) {
            this.player_two.overall_right_answer = 100 * (p[1].goals / p[1].questions_answered);
          }
        }
        this.$nextTick(() => this.$refs.p1?.$forceUpdate() || this.$refs.p2?.$forceUpdate());
      }
    });
  },
  computed: {
    sectionTitleText() {
      const session = this.$store.state.challenge.session_data;
      if (session && session.finished) {
        return 'Game ended';
      }
      return 'Current Game';
    },
    allSetup() {
      const as = this.$route.meta.as;
      if (as === 'host') {
        return !!this.$store.state.challenge.session_data.players[0].nickname;
      } else {
        return !!this.$store.state.challenge.session_data.invited;
      }
    },
    CTA() {
      if (this.allSetup) {
        const session = this.$store.state.challenge.session_data;
        if (session && session.finished) {
          return 'Challenge again';
        }
        return 'Access game';
      } else {
        return this.$route.meta.as == 'host' ? 'Create challenge!' : 'Accept challenge!';
      }
    },
    player_index() {
      const session = this.$store.state.challenge.session_data;
      if (session) {
        if (session.finished) {
          if (session.players[0].total_points > session.players[1].total_points) {
            return 0;
          } else if (session.players[0].total_points < session.players[1].total_points) {
            return 1;
          } else {
            return -1;
          }
        } else {
          return session.current_player;
        }
      }
      return 0;
    },
    playerOneImage() {
      return require('@/assets/images/player-one.png');
    },
    playerTwoImage() {
      return require('@/assets/images/player-two.png');
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/custom-vars/_colors.scss';

#challenge-game {
  background: $gradient-challenge-mode;

  .image-container-64 {
    object-fit: contain;
    width: 64px;
    height: 64px;
  }
  .current-game-grid {
    display: grid;
    grid-template-columns: 6fr 6fr;
    .player-avatar {
      margin-left: 4px;
    }
  }
}
</style>
