<template>
  <section id="challenge-main" :class="['result-' + result]">
    <div class="commet-background pt-4 pb-5 px-4">
      <h1 class="section-title mt-5 font-weight-700 mb-2" data-aos="fade-down" data-aos-delay="500">{{ title }}</h1>
      <h1 class="section-subtitle mb-4 font-weight-regular px-4" data-aos="fade-down" data-aos-delay="750">{{ subtitle }}</h1>
      <img alt="Challenge Mode Image" style="max-width: 312px" :src="image" class="my-2" data-aos="zoom-in" data-aos-delay="1000" />
      <div class="d-flex mt-4 flex-column" data-aos="zoom-in" data-aos-offset="-10">
        <div v-if="this.loading" class="flex-fill text-white d-flex justify-content-center align-items-center font-weight-700">
          <div class="loading-square bg-green-neon" style="margin-right:12px"></div>
          Loading
        </div>
        <button v-else-if="result !== 'winner'" type="button" class="btn bg-green-neon flex-fill rounded-button d-flex justify-content-center align-items-center" @click="action">
          Challenge again
        </button>
        <template v-else>
          <p class="text-start section-subtitle" data-aos="fade-right" data-aos-delay="1500" data-aos-duration="1000" data-aos-once="true" data-aos-offset="-250">
            {{ $t('message.share-with-your-friends') }}
          </p>
          <ul class="list-group-horizontal d-flex mb-2" data-aos="fade-right" data-aos-delay="1250" data-aos-duration="1000" data-aos-once="true" data-aos-offset="-250">
            <li v-for="social in socials" :key="social.id">
              <a
                target="_blank"
                @click="$emit('event', 'share', { on: social.title })"
                class="round-icon-wrapper bg-white"
                :title="$t('message.share-on', [social.title])"
                :data-action="dataAction(social.id)"
                :href="href(social.id)"
              >
                <img width="25" height="25" :src="getSocialMediaIcon(social.icon)" :alt="social.title" />
              </a>
            </li>
          </ul>
          <div class="d-flex mt-4" data-aos="zoom-in" data-aos-delay="3000" data-aos-offset="-250">
            <button type="button" class="btn bg-green-neon flex-fill rounded-button" @click="share">
              {{ $t('message.share-now') }}
            </button>
          </div>
        </template>
      </div>
    </div>
    <ChallengeCertification ref="certification" />
  </section>
</template>
<script>
import ChallengeCertification from '@/components/containers/ChallengeCertification.vue';
import { toBlob } from 'html-to-image';

export default {
  components: {
    ChallengeCertification
  },
  methods: {
    action() {
      alert(JSON.stringify({ action: 'new-challenge' }, null, 2));
    },
    getSocialMediaIcon(icon) {
      return require(`@/assets/images/${icon}.svg`);
    },
    share() {
      // this.$emit('event', 'share', { on: 'navigator share api' })

      this.$refs['certification'].$refs['parent'].classList.add('rendering');

      toBlob(this.$refs['certification'].$refs['parent']).then(blob => {
        this.$refs['certification'].$refs['parent'].classList.remove('rendering');

        var file = new File([blob], 'qotd-award.jpg', {
          type: 'image/jpeg'
        });
        var filesArray = [file];

        try {
          if (navigator.canShare && navigator.canShare({ files: filesArray })) {
            navigator.share({
              title: this.$t('message.lets-play') + ' ' + this.$t('app.title'),
              files: filesArray,
              text: this.getMessage(),
              url: this.getLink()
            });
          }
        } catch (err) {
          console.error(err);
        }
      });
    },
    href(id) {
      switch (id) {
        case 'email':
          return this.hrefEmail();
        case 'whatsapp':
          return this.hrefWhatsApp();
        case 'facebook':
          return this.hrefFacebook();
        case 'twitter':
          return this.hrefTwitter();
        case 'linkedin':
          return this.hrefLinked();
        case 'messenger':
          return this.hrefMessenger();
      }
    },
    dataAction(id) {
      switch (id) {
        case 'whatsapp':
          return 'share/whatsapp/share';
      }
    },
    getMessage() {
      const session = this.$store.state.challenge.session_data;
      const player_index = this.$route.meta.as == 'host' ? 0 : 1;
      const other = session.players[(player_index + 1) % 2].nickname;
      return `I have won a challenge against ${other} in the Question of the Day`;
    },
    getLink() {
      const link = 'https://profile.askqotd.com/' + this.$root.$i18n.locale;
      return link;
    },
    hrefMessenger() {
      const link = this.getLink(true);
      let href = `fb-messenger://share?link=${link}`;
      return href;
    },
    hrefTwitter() {
      const link = this.getLink(true);
      let Body = this.$t('message.lets-play') + ' ' + this.$t('app.title') + '! \n';
      Body += this.getMessage() + '\n';
      const HashTags = 'alexa,questionOfTheDay';
      let href = `http://twitter.com/share?text=${Body}&url=${link}&hashtags=${HashTags}`;
      return href;
    },
    hrefFacebook() {
      const link = this.getLink(true);
      let href = 'https://www.facebook.com/sharer/sharer.php?u=' + link;
      return href;
    },
    hrefWhatsApp() {
      let Body = this.$t('message.lets-play') + ' ' + this.$t('app.title') + '! ' + this.getLink();
      Body += this.getMessage();
      return 'whatsapp://send?text=' + Body;
    },
    hrefEmail() {
      const Subject = 'Alexa, ' + this.$t('app.title');
      let Body = '';
      Body += this.$t('message.lets-play') + ' ' + this.$t('app.title') + '! \n';
      Body += this.getMessage();
      Body += this.getLink();
      return `mailto:?Subject=${Subject}&body=${encodeURIComponent(Body)}`;
    }
  },
  props: {
    loading: { type: Boolean }
  },
  data: () => ({
    socials: [
      { id: 'email', title: 'Email', icon: 'Mail' },
      { id: 'messenger', title: 'Messenger', icon: 'Messenger' },
      { id: 'twitter', title: 'Twitter', icon: 'Twitter' },
      { id: 'facebook', title: 'Facebook', icon: 'Facebook' },
      { id: 'whatsapp', title: 'Whatsapp', icon: 'WhatsApp' }
    ]
  }),
  computed: {
    result() {
      const session = this.$store.state.challenge.session_data;
      if (session) {
        let winner = -1;
        let player = this.$route.meta.as == 'host' ? 0 : 1;
        if (session.players[0].total_points > session.players[1].total_points) {
          winner = 0;
        } else if (session.players[0].total_points < session.players[1].total_points) {
          winner = 1;
        }
        if (winner === -1) {
          return 'tie';
        } else if (winner === player) {
          return 'winner';
        } else {
          return 'looser';
        }
      } else {
        return 'undefined';
      }
    },
    image() {
      switch (this.result) {
        case 'tie':
          return require('@/assets/images/tie_emote.png');
        case 'winner':
          return require('@/assets/images/medal.svg');
        case 'looser':
          return require('@/assets/images/looser_emote.svg');
        default:
          return require('@/assets/images/swords.png');
      }
    },
    title() {
      switch (this.result) {
        case 'tie':
          return "Now that's teamwork";
        case 'winner':
          return 'Congratulations!';
        case 'looser':
          return 'Good effort';
        default:
          return '';
      }
    },
    subtitle() {
      const session = this.$store.state.challenge.session_data;
      const player_index = this.$route.meta.as == 'host' ? 0 : 1;
      const other = session.players[(player_index + 1) % 2].nickname;
      switch (this.result) {
        case 'tie':
          return `You and ${other} have tied`;
        case 'winner':
          return `You have won this challenge against ${other}!`;
        case 'looser':
          return `${other} has won this round`;
        default:
          return '';
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/custom-vars/_colors.scss';

#challenge-main {
  background: $gradient-challenge-mode;
  &.result-tie {
    background: $gradient-arts-and-entertainment;
    color: black;
  }
  &.result-winner {
    background: $gradient-general-knowledge;
    color: black;
  }
  &.result-looser {
    background: $gradient-literature;
    color: white;
  }
}
</style>
