<template>
  <section id="challenge-how-to-play" class="pt-4 pb-5 text-black px-4">
    <SectionTitleHeader title="How to Play" color="general-knowledge" class="pt-4 mb-5" />
    <p class="text-white section-subtitle text-start" data-aos="fade-left">Challenge mode allows you to play <b>Question of the Day</b> against a friend.</p>
    <p class="text-white section-subtitle text-start" data-aos="fade-left">
      All you have to do is enter the <b>Question of the Day</b> skill and answer the multiple choice question on your Alexa app or device.
    </p>
    <div class="chat-area d-flex flex-column">
      <Ballon text="On the original Rubik's Cube, how many stickers could be found on each side?" class="mb-4 max-width" />
      <Ballon text="Nine" class="mb-4 to-right font-weight-bold align-self-end" :answer="true" style="width: 120px"  />
      <Ballon text="Great job! You have won 5 points." class="mb-4 max-width" style="width: 260px" />
    </div>
    <p class="text-white section-subtitle text-start" data-aos="fade-left">
      Check <b>Question of the Day</b> again the next day to see if you have a new challenge question! After five rounds, whoever gets the most correct answers wins!
    </p>
  </section>
</template>
<script>
import Ballon from '@/components/Ballon.vue';
import SectionTitleHeader from '@/components/SectionTitleHeader.vue';

export default {
  components: { Ballon, SectionTitleHeader }
};
</script>
<style lang="scss" scoped>
@import '@/assets/custom-vars/_colors.scss';

#challenge-how-to-play {
  background: $gradient-literature;
}
</style>
