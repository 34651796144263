<template>
  <section id="challenge-main" class="text-white">
    <div class="commet-background pt-4 pb-5 px-4">
      <h1 class="section-title --tiny mt-5 font-weight-700 mb-4 font-italic" data-aos="fade-down" data-aos-delay="500">{{ title }}</h1>
      <img alt="Challenge Mode Image" :src="challengeModeIcon" width="242" height="172" class="my-5" data-aos="zoom-in" data-aos-delay="1000" />
      <div class="d-flex mt-4" data-aos="zoom-in" data-aos-offset="-10">
        <div v-if="this.loading" class="flex-fill text-white d-flex justify-content-center align-items-center font-weight-700">
          <div class="loading-square bg-green-neon" style="margin-right:12px"></div>
          Loading
        </div>
        <button v-else type="button" class="btn bg-green-neon flex-fill rounded-button d-flex justify-content-center align-items-center" @click="action">
          {{ CTA }}
        </button>
      </div>
      <p v-if="$route.meta.as == 'join'" class="font-italic section-subtitle mt-4">Or read on to learn how to play:</p>
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    action() {
      if (this.allSetup) {
        alert(JSON.stringify({ action: 'resume-challenge' }, null, 2));
      } else {
        this.$router.push({ name: 'challenge-' + this.$route.meta.as + '-setup', params: this.$route.params });
      }
    }
  },
  props: {
    loading: { type: Boolean }
  },
  computed: {
    allSetup() {
      const as = this.$route.meta.as;
      if (as === 'host') {
        return !!this.$store.state.challenge.session_data.players[0].nickname;
      } else {
        return !!this.$store.state.challenge.session_data.invited;
      }
    },
    CTA() {
      return this.$route.meta.as == 'host' ? 'Create challenge!' : 'Accept challenge!';
    },
    challengeModeIcon() {
      return require('@/assets/images/swords.png');
    },
    title() {
      if (this.$route.meta.as == 'host') {
        return 'Challenge a friend to play Question of the Day with you!';
      } else {
        if (this.$store.state.challenge.session_data) {
          return this.$store.state.challenge.session_data.players[0].nickname + ' has challenged you to play Question of the Day!';
        } else {
          return 'Someone has challenged you to play Question of the Day!';
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/custom-vars/_colors.scss';

#challenge-main {
  background: $gradient-challenge-mode;
}
</style>
