<template>
  <div>
    <p :style="{ opacity: showHighlightText ? 1 : 0 }" class="text-center text-history section-subtitle --large font-weight-bold mb-1">{{ highlightText }}</p>
    <div class="d-flex flex-column align-items-center multiplayer-player-stats py-4" :class="{ selected: showHighlightBorder }">
      <div class="image-container-64">
        <img class="player-avatar" :src="avatar" width="59" height="50" />
      </div>
      <h3 class="section-title --small text-white font-weight-700 mt-2 mb-4">{{ stats.nickname }}</h3>
      <div class="player-stat">
        <p class="stat-value --small text-history font-weight-700 mb-0">{{ stats.questions_answered }}</p>
        <p class="stat-description --small text-white">Questions answered</p>
      </div>
      <div class="player-stat">
        <p class="stat-value --small text-literature font-weight-700 mb-0">{{ stats.overall_right_answer }}%</p>
        <p class="stat-description --small text-white">Overall right answer</p>
      </div>
      <div class="player-stat">
        <p class="stat-value --small text-general-knowledge font-weight-700 mb-0">{{ stats.total_points }}</p>
        <p class="stat-description --small text-white">Total points</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    player: { type: Number, required: true },
    player_index: { type: Number, required: true },
    stats: { type: Object, required: true }
  },
  computed: {
    showHighlightBorder() {
      const session = this.$store.state.challenge.session_data;
      if (session.finished) {
        if (session.players[0].total_points > session.players[1].total_points) {
          return this.player == 0;
        } else if (session.players[0].total_points < session.players[1].total_points) {
          return this.player == 1;
        } else {
          return false;
        }
      } else {
        return this.player == this.player_index;
      }
    },
    highlightText() {
      const session = this.$store.state.challenge.session_data;
      if (session.finished) {
        if (session.players[0].total_points > session.players[1].total_points) {
          return session.players[0].nickname + ' won!';
        } else if (session.players[0].total_points < session.players[1].total_points) {
          return session.players[1].nickname + ' won!';
        } else {
          return "It's a tie";
        }
      } else {
        return 'Your turn!';
      }
    },
    showHighlightText() {
      const session = this.$store.state.challenge.session_data;
      if (session.finished) {
        if (session.players[0].total_points > session.players[1].total_points) {
          return this.player == 0;
        } else if (session.players[0].total_points < session.players[1].total_points) {
          return this.player == 1;
        } else {
          return true;
        }
      } else {
        const yourIndex = this.$route.meta.as == 'host' ? 0 : 1;
        return this.player == this.player_index && yourIndex == this.player_index;
      }
    },
    avatar() {
      return this.player == 0 ? require('@/assets/images/player-one.png') : require('@/assets/images/player-two.png');
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/custom-vars/_colors.scss';

.multiplayer-player-stats {
  border-width: 3px;
  border-style: solid;
  border-radius: 10px;
  border-color: transparent;
  &.selected {
    border-color: $history;
    background: rgba(255, 255, 255, 0.21);
  }
}
</style>
