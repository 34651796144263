<template>
  <div class="certification certification-bg d-flex flex-column align-items-center justify-content-center" ref="parent" :style="{ backgroundImage: backgroundImage }">
    <app-title-header class="app-title-header text-general-knowledge-darker" />
    <img alt="Challenge Mode Image" width="162px" height="226px" :src="image" class="my-2" />
    <div class="ribbon" :style="{ backgroundImage: ribbonImage }">
      <svg viewBox="0 0 121.35555 21.872221" width="344" height="62">
        <g transform="translate(-39.863892,-99)">
          <path id="curve" style="fill: none; stroke: none" d="m 54.641997,116.27254 c 24.214369,-1.10679 51.682733,-2.32731 91.406173,0" />
          <text width="121" fill="white" font-family="'Greycliff Regular', sans-serif" style="text-anchor: middle; font-weight: bold" letter-spacing="0.75">
            <textPath xlink:href="#curve" font-size="10px" startOffset="50%">
              WINNER
            </textPath>
          </text>
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
import AppTitleHeader from '../AppTitleHeader.vue';
export default {
  components: { AppTitleHeader },
  computed: {
    image() {
      return require('@/assets/images/medal.svg');
    },
    backgroundImage() {
      return `url(${require('@/assets/images/challenge_certification_bg.svg')})`;
    },
    ribbonImage() {
      return `url(${require('@/assets/images/challenge_certification_ribbon.svg')})`;
    }
  }
};
</script>
<style lang="scss">
@import '@/assets/custom-vars/_colors.scss';
.certification {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 400px;
  height: 400px;
  z-index: -1;
  visibility: hidden;
  background-size: 100% 100%;
  background-position: 0px 0px;
  background-repeat: no-repeat;
  border: 1px solid black;
  .app-title-header {
    top: 4px;
  }
  &.rendering {
    visibility: initial;
  }
  > .ribbon {
    width: 344px;
    height: 88px;
    background-size: 100% 100%;
    background-position: 0px 0px;
    background-repeat: no-repeat;
  }
}
.inner-shadow {
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.4);
}
.outer-shadow {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}
.rounded-corners {
  border-radius: 12px;
}
</style>
